.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 0.5rem;
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    border-radius: 10px;
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .HeaderText {
    width: 80%;
    display: flex;
    align-items: center;
    padding:.5rem;
    justify-content: flex-start;
  }
  .HeaderText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .CreateSection{
    display: flex;
    flex-direction: column;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin: 0 1rem;
    padding:.2rem 1rem;
  }
  .InputContainer{
    display: flex;
    flex-direction: row;
    margin: 6px 0;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .Label{
    font-weight: 500;
    width: 10rem;
  }
  .InputContainer input {
    font-family: "Poppins";
    width: 80%;
    font-size: 1rem;
    padding: 0.2rem 0;
    /* text-align: center; */
    margin: 0.2rem 0;
    border: none;
    border-bottom: 1px dotted #696969c1;
    outline: none;
    color: #3e4486;
  }
  .InputContainer input::-webkit-input-placeholder {
    /* text-align: center; */
    text-transform: uppercase;
    font-size: .9rem;
    color: #a7a6a6;
  }
  .InputContainer textarea{
    width: 80%;
    resize: none;
    outline: none;
    color: #3e4486;
    font-size: 1rem;
    border: none;
    border-bottom: 1px dotted #696969c1;
  }
  .InputContainer textarea::-webkit-input-placeholder {
    /* text-align: center; */
    text-transform: uppercase;
    font-size: .9rem;
    color: #a7a6a6;
  }
  .InputContainer select{
    border: none;
    background: none;
    border-bottom: 2px solid #939393;
    padding:  0;
    width: 300px;
    outline: none;
    font-size: 1rem;
    color: #8f2f2f;
  }
  .editSection{
    width: 80%;
  }
  .checkboxContainer{
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
  }
  .checkboxContainer .checkboxInput{
    margin-right: 5px;
    width: 10%;
    transform: scale(1.1);
  }
  .checkboxContainer .checkboxInputLabel{
    font-size: 1.1rem;
  }
  .checkboxGrid {

   width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust column width as needed */
    gap: 5px; /* Adjust the gap between items */
  }
  
  .checkboxItem {
    display: flex;
    align-items: center;
  }
  .checkboxContainer .checkboxLabel{
    font-weight: 500;
    width: 10rem;
  }
  .Buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .create {
    background: #adc2e0;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #2445b3;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #568cbe;
  margin: 0 1rem;
  }
  .cancel {
    background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
    
  }
  .errorMessages {
    padding: 0rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: red;
    font-size: 1.2rem;
  }
  .errorMessages p{
    margin: 4px 0;
  }