.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 1rem;
    background-color: rgb(241, 239, 239);
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .HeaderDiv{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   background-color: #c29455;
    align-items: center;
    margin-top: 1rem;
    color: #fff;
    height: 4rem;
    border-radius: 10px;
  
  }
  .label{
font-size: 10px;
  }
  .Title{
    width: 100%;
    padding:0;
    top: 0;
  }
  .Title p{
    margin: 0;
    padding: 5px 1rem ;
    font-size: 1.5rem;
  }

  .Header {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1a1e5d;
    align-items: center;
    margin-top: 1rem;
    color: #fff;
    height: 4rem;
    border-radius: 10px;
  }
  .HeaderText {
    width: 100%;
    margin-left: 1rem;
  }
  .HeaderText p {
    font-size: 1.25rem;
  }
  .HeadButton {
    width: 10%;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .HeadButton button {
    background-color: cornflowerblue;
    font-size: 1.2rem;
    outline: none;
    border-radius: 7px;
    border: 1px solid;
    padding: 2px 10px;
  }
  .HeaderButton{
    width: 15%;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .HeaderButton button {
    background-color: cornflowerblue;
    font-size: 1.2rem;
    outline: none;
    border-radius: 7px;
    border: 1px solid;
    padding: 2px 10px;
    margin: 0 3px;
  }
  .Syllabus{
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;

  }
  .Heading{
   width: 100%;
  }
  .Heading p{
    font-size: 1rem;
    text-decoration: underline;
    margin: 1rem ;
    text-align: center;
  }
  .Cards{
    width: 100%;
    height: 4rem;
    border: 1px solid;
    border-radius: 4px;
   /* display: flex;
   flex-direction: column; */
  }
  .Cards p{
    font-size: 1rem;
    padding:0 1rem ;
    text-align: center;
  }
  .Buttons{
    width: 100%;
    display: flex;
    justify-content:end;
    margin-top: 1rem;
  }
  .Buttons button{
  font-size: 1rem;
  outline: none;
  border-radius: 5px;
  border: none;
  width: 4rem;
  padding: 3px 0;
  border: 1px solid;
  margin: 0 0.5rem;
  }
  .List{
    width: 100%;
    border: 1px solid;
  }
  .AddressSection {
    width: 25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AddressSection form {
    background-color: #fff;
    padding:2rem 2rem;
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
  }
  .AddressSection form input
  {
    font-family: "Poppins";
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 5px 1px;
    text-align: center;
    border-radius:5px;
    margin: 10px 0;
    border: 2px solid #2a2a2ac1;
    /* outline:.25rem solid #000; */
  }
  .AddressSection form input:focus {
    /* outline: 0.25rem solid #1b8fe7; */
    border-color: transparent;
  }
  .AddressSection form {
    align-items: center;
  }
  .AddressSection form button {
    width: 70%;
    border-radius: 50px;
    background-color: #3b82f6;
    border: none;
    outline: none;
    height: 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    margin-top: 1rem;
  }
  .AddressSection form input::-webkit-input-placeholder {
    text-align: center;
    text-transform: uppercase;
  }
  .Overlay{
    width: 84vw;
    height: 100vh;
    position: absolute;
    background-color: transparent;
    z-index: 9;
}
.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 8rem;
  z-index: 99;
}
.popupText p {
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 25px;
}
.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.PopupButton button {
  margin: 0 .5rem;
  padding: .5rem 1rem ;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
}
  