.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
}
.Container::-webkit-scrollbar {
  display: none;
}
.HeaderDiv {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  /* border-radius: 10px; */
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.label {
  font-size: 10px;
}
.Title {
  width: 33%;
  align-items: center;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
}
.Title p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.clubNames {
  width: 33%;
  align-items: center;
  display: flex;
  padding: 1rem;
  justify-content: center;
}
.clubNames p {
  margin: 0;
  font-size: 1.5rem;
}

.HeaderButton {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.HeaderButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  width: 115px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.clubSection {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  /* height: 100%; */
  padding: 0 1rem;
  /* /* width: 100%;  */
}
.Club {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Cards {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.5rem;
  height: 8rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.Cards p {
  font-size: 1.25rem;
  color: #857073;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
}
.cardTop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.cardBottom {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  /* padding: 1rem; */
  height: 50%;
}
.viewButton {
  color: #b4b409;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}

.AddressSection {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  width: 30rem;
}
.popupClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
  cursor: pointer;
}
.createSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  height: 70%;
}
.clubName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 100%;
}
.clubName label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 100%;
}
.description label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #74174e;
}
.createSection input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
/* .createSection input:focus {
  outline: 3px solid #606e7a;
  border-color: transparent;
} */
.createButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 30%;
}
/* .createSection input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
} */
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.deletePopup {
  width: 30rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
   cursor: pointer;

}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}
.Delete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.DeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
