.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  /* border-radius: 10px; */
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
  padding-left: 1rem;
}
.planningSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.sessionCreation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
}
.inputContainer,
.inputContainerSecond {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}
.inputContainerSecond {
  margin-top: 3rem;
}
.inputValue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 1rem;
  width: 33%;
}
.inputSearchSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 1rem;
  width: 100%;
}
.selectionSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1.5rem;
}
.selectionSection button {
  background-color: #e0e5f2;
  border: none;
  width: 150px;
  height: 2.5rem;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  cursor: pointer;
}
.selectionSection button:nth-child(2) {
  margin-left: 1rem;
}

.inputValue label,
.inputSearchSection label {
  font-family: "Poppins";
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2b3674;
}
.inputValue input,
.inputValue select {
  border: 1px solid #e0e5f2;
  width: 90%;
  height: 2.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
}
.inputValue input::placeholder {
  color: #a3aed0;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000002b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.userSelectPopup {
  position: absolute;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  width: 45rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
}
.userPopupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10%;
}
.userPopupHeader p {
  font-family: "Poppins";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
}
.userPopupHeader svg {
  cursor: pointer;
  font-size: 1.35rem;
}
.userPopupSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70%;
}
.popupInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #e0e5f2;
  width: 100%;
  height: 25%;
  border-radius: 8px;
  margin-top: 0.75rem;
  overflow-y: auto;
}
.popupInput::-webkit-scrollbar {
  display: none;
}
.popupInput input {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  margin-left: 0.75rem;
  height: 90%;
}
.popupInput input::placeholder {
  color: #a3aed0;
}
.chip {
  display: flex;
  align-items: center;
  background: #7090b033;
  border-radius: 2rem;
  justify-content: space-between;
  min-width: 7rem;
  height: 2rem;
  margin: 0.5rem;
}
.chip p {
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: #222b45;
  padding: 0 0.5rem;
}
.chip svg {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.userPopupList {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  height: 75%;
  overflow-y: auto;
}
.userPopupList::-webkit-scrollbar {
  display: none;
}
.popupValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1.25rem;
}
.popupValue input {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-left: 1rem;
}
.popupValue p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2d3653;
  padding-left: 1rem;
}

.userButtonList {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}
.userButtonList button {
  background-color: #ffffff;
  border: 2px solid #e0e5f2;
  width: 150px;
  height: 2.5rem;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  cursor: pointer;
}
.userButtonList button:nth-child(2) {
  margin-left: 1rem;
  background-color: #e0e5f2;
  color: #000000;
  border: none;
}
.inputContainerThird {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  margin-top: 1rem;
}
.inputContainerThird button {
  background-color: #ffffff;
  border: 2px solid #e0e5f2;
  width: 150px;
  height: 2.5rem;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  cursor: pointer;
}
.inputContainerThird button:nth-child(2) {
  margin-left: 1rem;
  background-color: #e0e5f2;
  color: #000000;
  border: none;
}
.sessionCalender {
  width: 97%;
  margin-top: 1rem;
  height: 50%;
}
.eventPopup {
  position: absolute;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  width: 45rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
}
.eventPopupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10%;
}
.eventPopupHeader p {
  font-family: "Poppins";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
}
.eventPopupHeader svg {
  cursor: pointer;
  font-size: 1.35rem;
}
.eventPopupSection {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.eventInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.eventInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
}
.eventInput label {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2b3674;
}
.eventInput input {
  border: 1px solid #e0e5f2;
  width: 95%;
  height: 2.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
}
.eventInput input::-webkit-input-placeholder,
.eventSecondInput textarea ::placeholder {
  color: #a3aed0;
}
.eventSecondInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.eventSecondInput textarea {
  border: 1px solid #e0e5f2;
  width: 98%;
  height: 4.5rem;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
  resize: none;
}
.eventButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}
.eventButtons button {
  background-color: #ffffff;
  border: 2px solid #e0e5f2;
  width: 150px;
  height: 2.5rem;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  cursor: pointer;
}
.eventButtons button:nth-child(2) {
  margin-left: 1rem;
  background-color: #e0e5f2;
  color: #000000;
  border: none;
}
