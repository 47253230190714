.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.75rem 0;
  width: 100%;
}
.Active {
  background: #c6eeff;
}
.page {
  font-size: 1.15rem;
  color: #1a2026;
  margin: 0 0.5rem;
  border: 2px solid #829bde;
  border-radius: 4rem;
  /* padding: 0.25rem; */
  width: 2.5rem;
  text-align: center;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page a {
  cursor: pointer;
}
.previous {
  font-size: 1.25rem;
  color: #d8292a;
  margin: 0 0.5rem;
  border: 1px solid #a43838;
  border-radius: 3rem;
  /* padding: 0.25rem; */
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  text-align: center;
}
.previous a {
  cursor: pointer;
}
.next {
  font-size: 1.25rem;
  color: #d8292a;
  margin: 0 0.5rem;
  border: 1px solid #a43838;
  border-radius: 3rem;
  /* padding: 0.25rem; */
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  text-align: center;
}
.next a {
  cursor: pointer;
}
