.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* padding: 0 1rem; */
  background-color: #fff;
}
.Container::-webkit-scrollbar {
  display: none;
}
.Header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  /* border-radius: 10px; */
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.HeaderText {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.HeaderText p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.OrgList {
  width: 80%;
}
.OrgList select {
  outline: none;
  border: none;
  width: 200px;
  font-size: 1rem;
  text-align: center;
  padding: 0.25rem;
  border-radius: 15px;
}

.HeaderText p {
  font-size: 1.5rem;
}
.HeadButton {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.HeadButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  /* width: 115px; */
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.secondHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.select_section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 1rem;
  border: 2px solid #efefef;
  border-radius: 16px;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
}
.Org_Select {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.Org_Select select {
  border: none;
  background: none;
  border-bottom: 2px solid #3c149e;
  padding: 1rem 0;
  width: 300px;
  outline: none;
  font-size: 1.25rem;
  color: #106b84;
}
.Club_Select {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.Club_Select select {
  border: none;
  background: none;
  border-bottom: 2px solid #3c149e;
  padding: 1rem 0;
  width: 300px;
  outline: none;
  font-size: 1.25rem;
  color: #106b84;
}
.Grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Cards {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.5rem;
  height: 8rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.cardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.cardContent p {
  font-size: 1.25rem;
  color: #857073;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
}
.cardBottom {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* width: 50%; */
  /* padding: 1rem; */
  height: 50%;
}
.viewButton {
  color: #b4b409;
}
.courseMap {
  color: #09b409;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}
.iconContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 2rem;
  margin-top: 2rem;
}
.buttons button {
  font-size: 1rem;
  outline: none;
  border-radius: 5px;
  border: none;
  width: 4rem;
  padding: 4px 0;
  border: 1px solid;
  margin: 0 0.5rem;
}

.successPopup {
  width: 35rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AddressSection {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AddressSection form {
  background-color: #fff;
  padding: 2rem 2rem;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
}
.AddressSection form input {
  /* font-family: "Poppins"; */
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  padding: 5px 1px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 3px solid #696969c1;
  /* outline:.25rem solid #000; */
}
.AddressSection form textarea {
  /* font-family: "Poppins"; */
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  padding: 5px 1px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 3px solid #696969c1;
  font-size: 1.25rem;
  /* outline:.25rem solid #000; */
}
.AddressSection form input:focus {
  /* outline: 0.25rem solid #1b8fe7; */
  border-color: transparent;
}
.AddressSection form {
  align-items: center;
}
.AddressSection form button {
  width: 70%;
  border-radius: 50px;
  background-color: #3b82f6;
  border: none;
  outline: none;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 1rem;
}
.AddressSection form input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
}

.menu {
  display: flex;
  flex-direction: column;
  /* margin: 2rem 0; */
  /* height: 100%; */
  padding: 0 1rem;
}

.SubcategoryPopup {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  background-color: #ffffff;
  /* border-radius: 1rem; */
  height: 90vh;
  padding: 2rem;
  border-radius: 10px;
}
.subCategorySection {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  border-bottom: 2px solid #fff;
  width: 100%;
  padding: 0.75rem;
  box-shadow: 0 3px 8px #0000003d;
  border-radius: 8px;
}
.headLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.navigationLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.navigationLinks span {
  color: #c71313;
  font-size: 1.25rem;
  margin: 0 0.25rem;
}
.linkLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.linkLeft p {
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #004f96;
  cursor: pointer;
}
.linkRight {
  display: flex;
  align-items: center;
}
.linkRight span {
  font-size: 1.25rem;
  color: #d4bd33;
}
.linkRight p {
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #1a8f8f;
  cursor: pointer;
}
.headRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.subButton {
  background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #56197e;
  /* width: 170px; */
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #6356be;
  margin: 0 0.25rem;
}
.topicButton {
  background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #56197e;
  width: 150px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #6356be;
  margin: 0 0.25rem;
}
.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0.75rem;
  width: 90%;
}
.Buttons p {
  font-size: 1.25rem;
  color: #1f232a;
}

.addModule {
  background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #56197e;
  width: 150px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #6356be;
  margin: 0 0.25rem;
}
.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0.75rem;
  width: 90%;
}
.Buttons p {
  font-size: 1.25rem;
  color: #1f232a;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-width: 90%;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
  margin-top: 20px;
}

.moduleclose {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.moduleActions {
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
  color: black;
}

.editModule {
  cursor: pointer;
}

.editModule:hover {
  color: #0076de;
}

.deleteModule {
  cursor: pointer;
}

.deleteModule:hover {
  color: red;
}

.moduleclear {
  border-radius: 6px;
  background-color: #e1e4ee;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
  margin-top: 10px;
}

.moduleclear:hover {
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
  margin-top: 10px;
}

.Table {
  width: 100%;
  border-spacing: 0;
  padding: 0 1rem;
}
.Table tr {
  text-align: left;
  border: 2px solid;
}
.Table td {
  border: none;
  height: 2.5rem;
  border-bottom: 2px solid #dbdbdb;
}

.TableButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.asessments {
  border-radius: 6px;
  background: #e0fbdb;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #57aa3f;
  padding: 0.5rem;
  border: 2px solid #a0d77c;
  cursor: pointer;
  margin: 0 0.25rem;
}
.ExamICon {
  font-size: 1.1rem;
  margin: 0 6px;
}
.ViewTopic {
  width: 5rem;
  border-radius: 6px;
  background: #f6fbdb;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #9caa3f;
  padding: 0.5rem;
  border: 2px solid #d7d67c;
  cursor: pointer;
  margin: 0 0.25rem;
}
.DeleteTopic {
  width: 5rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
  margin: 0 0.25rem;
}
.UpdateTopic {
  width: 5rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
  margin: 0 0.5rem;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
}
.secondOverlay {
  width: 98.96vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(66, 65, 65, 0.23);
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
}
.close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.close svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.close svg:hover {
  color: red;
}
.courseMapping {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  width: 30rem;
}
.courseMapClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.courseMapClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.courseMapSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
}
.courseMapButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50%;
}
.Category_Create {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  width: 30rem;
}
.categoryClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.categoryClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.categorySection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
}
.categorySection input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.categoryName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 100%;
}

.categoryName label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.categoryDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 100%;
}
.categoryDescription label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #74174e;
}

.popupButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50%;
}
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}

.SubCategory_Create {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 9;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  width: 30rem;
}
.subcategoryClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.subcategoryClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.SubGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.SubCards {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.5rem;
  height: 8rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.SubcardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.SubcardContent p {
  font-size: 1.25rem;
  color: #857073;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
}
.bottom_left {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  /* padding: 1rem; */
  height: 50%;
}
.topicCreate {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
  width: 30rem;
}
.topicClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.topicClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}

.topicSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.5rem;
}
.topicTextArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  margin: 1rem 0;
}

.subBottom {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 0.75rem 0;
  overflow-y: scroll;
}
/* .subBottom::-webkit-scrollbar {
  display: none;
} */
.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}
.topicContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #797b8e;
  padding: 0.5rem;
}
.topicValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.topicValue p {
  font-size: 1.5rem;
  color: #191414;
  font-weight: 500;
  cursor: pointer;
}
.topicEdit {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  /* border-radius: 10px; */
  padding: 1rem;
  z-index: 999;
}
.topicEditClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.25rem;
}
.topicEditSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
  overflow: hidden;
}
.topicEditSection textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.25rem;
  height: 100px;
  /* border-radius: 1rem; */
  text-align: center;
}
.topicEditSection button {
  margin-top: 1rem;
  font-size: 1.15rem;
  border: none;
  background: #7474cf;
  width: 100px;
  padding: 0.25rem;
  border-radius: 10px;
  color: #403535;
}

.DeletePopup {
  width: 30rem;
  height: 15rem;
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
  padding-bottom: 10px;
}

.popupText span {
  color: #ff0000;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500;
}
.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  padding-top: 20px;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}

.moduleHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: darkblue;
}

.lab {
  border-radius: 6px;
  background: #f5dad0;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #ac1b0b;
  padding: 0.5rem;
  border: 2px solid #cf3a2a;
  cursor: pointer;
  margin: 0 0.25rem;
}

.ActionSwitch {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border: 1px solid rgb(177, 176, 176);
  border-radius: 5px;
}

.ActionSwitch:hover {
  border: 1px solid rgb(46, 46, 46);
  border-radius: 5px;
}

.moduleh3 {
  padding: 10px;
}

.examModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.examModalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 800px;
  max-width: 100%;
}

.examModuleclose {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.examModalButtons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.weekdays {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.weekdays p {
  width: 30%;
  font-weight: 500;
  color: #0076de;
}

.examModalContent h2 {
  font-weight: 800;
  color: #004f96;
}

.selectDynamic {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.popHead {
  padding-bottom: 2rem;
}

.schedulexam {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 20px;
}

.scheduleExamBox {
  background: #0076de50;
  width: 48vh;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
}

.scheduleExamCard h3 {
  color: darkblue;
  padding-bottom: 5px;
}

.scheduleExamCard h4 {
  color: darkblue;
}

.examActions {
  display: flex;
  font-size: 1.5rem;
  gap: 1rem;
}

.schDelete,
.schEdit {
  cursor: pointer;
  color: darkblue;
}

.schDelete:hover {
  color: red;
}

.schEdit:hover {
  color: rgb(1, 138, 42);
}

.ModulenameEditBox {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  gap: 1 rem;
}

.moduleUpdateBtnBox {
  display: flex;
  justify-content: space-between;
}

.moduleEditSave {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}

.moduleEditClose {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}

.actionBox {
  display: flex;
  gap: 1rem;
}

.moduleListingBox {
  padding: 20px;
  margin: 1rem;
  background-color: #0076de18;
  border-radius: 8px;
}

.moduleListingBoxBottom {
  padding: 20px;
  margin: 1rem;
  background-color: transparent;
  border-radius: 8px;
}

.TimeQuesBox {
  display: flex;
  justify-content: space-between;
}

.tBox {
  width: 45%;
}

.subBottom::-webkit-scrollbar {
  width: 10px;
}

.subBottom::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

.subBottom::-webkit-scrollbar-thumb {
  background: #005096;
  border-radius: 10px;
}

.subBottom::-webkit-scrollbar-thumb:hover {
  background: #012c52;
}

.feildBox {
  /* padding-bottom: 30px; */
  /* padding-top: 25px; */
  padding-top: 15px;
}

.feildBoxAdd {
  padding-top: 15px;
}

.ModulenameEditFeild {
  padding-bottom: 20px;
}

.feildBox p {
  font-size: 18px;
  font-weight: 600;
}

.feildBoxAdd p {
  font-size: 18px;
  font-weight: 500;
}
