.Container {
  width: 100%;
  height: 100vh;
  padding: 0 1rem;
}
.topicHead {
  padding: 0.25rem;
  display: flex;
  /* background: #b08693; */
  line-height: 0;
  height: 50px;
  color: #fff;
  /* width: 100%; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Tab {
  border: 2px solid #bbb8b8;
  border-radius: 1rem;
  height: fit-content;
  background: linear-gradient(#fff, #dbdbdb);
  padding: 1rem;
}
.LessonDiv {
  padding: 0rem 1rem;
}
.LessonHead {
  /*  width: 100%; */
  background-color: rgb(178, 133, 0);
  border: 2px solid #bbb8b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 1rem;
}
.LessonHead p {
  font-size: 1.25rem;
  color: #1a1e5d;
  font-weight: 600;
  margin: 0;
}
.SecondHead {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 21%;
}
.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 8rem;
  font-size: 1.5rem;
  cursor: pointer;
  align-items: center;
}
.topicDetal {
  padding: 5px 2rem;
  height: 36rem;
  background-color: #fff;
  /* border: 2px solid #bbb8b8; */
  border-radius: 5px;
  overflow-y: scroll;
}
.topicDetal p {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}
.imageView img {
  width: 100%;
  height: 100%;
}
.Blank {
  color: transparent;
  opacity: 0;
}
.editbutton {
  align-items: center;
  display: flex;
  justify-content: center;
}
.editbutton button {
  width: 100px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  /* border: 2px solid #acac9a; */
  padding: 0.5rem;
  background: #2f6463;
  border-radius: 10px;
  border: none;
}
.lessonEdit {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-35%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 30rem;
  padding: 0.75rem;
  background-color: #e9e9e9;
  border-radius: 0.5rem;
}
.editClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.25rem;
}
.editSection {
  height: 25rem;
  background-color: #f0f0f0;
  overflow-y: scroll;
  width: 100%;
  margin: 1.5rem 0;
}
.lessonEditButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.lessonEditButton button {
  font-size: 1.25rem;
  width: 100px;
  background: #358198;
  color: #fff;
  border: none;
  padding: 0.25rem;
  border-radius: 10px;
}
