.container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 1rem;
  }
  .container::-webkit-scrollbar {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    /* border-radius: 10px; */
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .headerText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
  }
  .headerText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .performance {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
}
  .assessmentSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
}
.dailyDrillCard{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    gap: 2rem;
    padding: 2rem;
    border-radius: 33px;
    background-color: #FFE79D;
}
.weeklyWorkOutCard{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    gap: 2rem;
    padding: 2rem;
    border-radius: 33px;
    background-color: #B7FFF7;
}
.assessmentCardLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}
.assessmentCardImage{
    width: 6rem;
    height: 6rem;
}
.assessmentCardImage img{
    width: 100%;
    height: 100%;
}
.assessmentCardRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
}
.assessmentCardRight p{
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #000;
    margin: 0;
}
.assessmentCardRight h4{
    font-size: 60px;
    font-weight: 600;
    line-height: 56.02px;
    text-align: left;
    margin: 0;
}
.dailyDrillCard .assessmentCardRight h4{
    color: #FF8800;
}
.weeklyWorkOutCard .assessmentCardRight h4{
    color: #038E7F;
}
.assessmentCardRight span{
    font-size: 20px;
    font-weight: 500;
    line-height: 32.41px;
    text-align: left;
    color: #5F5F5F;
}
.moduleSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;

}
.moduleHeder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.moduleHeder p{
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #595BDB;
}
.numberBarSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.numberBar {
    width: 100%;
    height: 7px;
    background-color: #F0F0F0;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
}

.number, .selectedNumber {
    margin-left: 3rem; 
}




.selectedNumber {
    background: #595BDB;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    line-height: 13.33px;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
}

.number {
    background: #CDCEFF;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 13.33px;
    text-align: left;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;

}
.moduleSubHeder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
}
.moduleSubHeder p{
    font-size: 16px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
    color: #5F5F5F;
    margin: 0;
}
.moduleSubHeder span{
    font-size: 16px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
    color: #000;
}

.progressBarSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.progressBar {
    width: 100%;
    height: 10px;
    background-color: #F0F0F0;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}
.progressBarCount p{
    font-size: 16px;
    font-weight: 600;
    line-height: 30.px;
    text-align: center;
    color: #595BDB;
    margin: 0;
}
.roundGraphSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem 0;
}
.roundGraphCard{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
}
.roundGraphCardHeder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.roundGraphCardHeder p{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    margin: 0;
    color: #000;
}
.roundGraphCardBottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.roundGraphCardBottom p{
    font-size: 14px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    margin: 0;
    color: #3E3D42;
}
.roundGraph{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 5.9rem;
    position: relative;
}
.roundGraphData{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.roundGraphData p{
    font-size: 22px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    margin: 0;
    color: #595BDB;
}
.learningSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 2rem;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;
}
.learningSectionLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    padding-right: 1rem;
}
.learningHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.learningHeader p{
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    margin: 0;
    color: #5F5F5F;
}
.learningSectionRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    border-left: 1px solid #595BDB;
    padding-left: 1rem;
}
.learningSubHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    padding: 1rem 0;
}
.learningSubHeaderLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    border-radius: 4px;
    background-color: #D9D9D9;
}
.subHeaderCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .35rem;
    width: 3rem;
    border-right: 0.4px solid #89889061;
}
.subHeaderCard:last-child{
    border: none;
}
.subHeaderCard p{
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    margin: 0;   
    color: #000; 
}
.selectedSubHeaderCard{
    background-color: #000;
    border:none;
    border-radius: 4px;
}
.selectedSubHeaderCard p{
    color: #ffffff; 
}
.learningSubHeaderRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

}
.learningSubHeaderRight select{
    border: 1px solid #878787;
    border-radius: 23px;
    padding:.2rem 1rem;
    width: 200px;
}
.barGraph{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 450px;
}
.levelHeader{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}
.levelHeaderCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
}
.levelHeaderCard h4{
    font-size: 11px;
    font-weight: 500;
    line-height: 13.2px;
    text-align: left;
    color: #000;    
}
.levelHeaderCard p{
    font-size: 9px;
    font-weight: 500;
    line-height: 9.6px;
    text-align: left;
    margin: 0;
    color: #3E3D42;
}
.levelHeaderCard span{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #FFBE00;
}
.levelHeaderCard:last-child span{
    background-color: #595BDB;
}
.assessmentSection{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.assessmentSectionLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem ;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;
    flex: 1;
}
.assessmentSectionRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem ;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;
    flex: 1;
}
.assessmentScore{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFBE00;
    border-radius: 50%;
    width: 6vw;
    height: 12vh;
}
.assessmentScore p{
    margin: 0;
    font-size: 9px;
    font-weight: 500;
    line-height: 10.8px;
    text-align: center;
    color: #000;
}
.assessmentScore h4{
    font-size: 35px;
    font-weight: 600;
    line-height: 42.01px;
    text-align: left;
    color: #fff;
}
.lineGraph{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 450px;
}
.secondHead {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .select_section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    border: 2px solid #efefef;
    border-radius: 16px;
    margin: 1rem;
    box-shadow: 0 3px 8px #0000001f;
  }
  .Org_Select {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .Org_Select select {
    border: none;
    background: none;
    border-bottom: 2px solid #3c149e;
    padding: 1rem 0;
    width: 300px;
    outline: none;
    font-size: 1.25rem;
    color: #106b84;
  }