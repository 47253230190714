.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* background-color: rgb(241, 239, 239); */
}
.Container::-webkit-scrollbar {
  display: none;
}
.Header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  /* border-radius: 10px; */
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.HeaderText {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.HeaderText p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.HeadButton {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.HeadButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  /* width: 115px; */
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.secondHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.selectLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
}
.List {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  /* margin: 1rem 0; */
  padding: 1rem;
  row-gap: 1rem;
  min-height: 60vh;
}
.pageContainer {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  justify-content: center;
}
.userCards {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.75rem;
  height: 10rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.cardTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem;
}
.userImage {
  font-size: 2rem;
  color: #767863;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.95rem;
}
.userDetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.userName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.userName p {
  color: #321e1e;
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 600;
}
.userName p:nth-child(3) {
  color: #8f2f2f;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.userName span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.role {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.role p {
  color: #321e1e;
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 600;
}
.role p:nth-child(3) {
  color: #8f2f2f;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.role span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.cardBotton {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.bottom_right button {
  border: none;
  background: #454545;
  font-size: 1rem;
  padding: 0.5rem;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 130px;
}

.cardBotton svg {
  margin: 0 0.5rem;
  font-size: 1.25rem;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}
.passwordRest {
  color: #168a23;
}
.forward {
  color: #e5e346;
}

.Buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Buttons button {
  font-size: 1rem;
  outline: none;
  border-radius: 5px;
  border: none;
  width: 4rem;
  padding: 4px 0;
  border: 1px solid;
  margin: 0 0.5rem;
}
.passwordInput {
  border: none;
  background-color: transparent;
  /* width: 100%; */
  text-align: center;
  font-size: 1rem;
}
.AddressSection {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AddressSection form {
  background-color: #fff;
  padding: 2rem 2rem;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
}
.AddressSection form input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  padding: 5px 1px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 2px solid #2a2a2ac1;
  /* outline:.25rem solid #000; */
}
.AddressSection form input:focus {
  /* outline: 0.25rem solid #1b8fe7; */
  border-color: transparent;
}
.AddressSection form {
  align-items: center;
}
.AddressSection form button {
  width: 70%;
  border-radius: 50px;
  background-color: #3b82f6;
  border: none;
  outline: none;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 1rem;
}
.AddressSection form input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
}

.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 8rem;
  z-index: 99;
}
.popupText p {
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 25px;
}
.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.PopupButton button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
}
.select_section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 1rem;
  border: 2px solid #efefef;
  border-radius: 16px;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
}
.org_select {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.org_select select {
  border: none;
  background: none;
  border-bottom: 2px solid #939393;
  padding: 1rem 0;
  width: 300px;
  outline: none;
  font-size: 1.25rem;
  color: #8f2f2f;
}
.club_select {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.club_select select {
  border: none;
  background: none;
  border-bottom: 2px solid #939393;
  padding: 1rem 0;
  width: 300px;
  outline: none;
  font-size: 1.25rem;
  color: #8f2f2f;
}
.userManagement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.UserManagementHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.Search {
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 1px solid #939393;
  line-height: 0;
  padding: 0 1rem;
  justify-content: space-between;
}
.Search:hover {
  border-bottom: 2px solid #735c5c;
}
.Search svg {
  font-size: 1.75rem;
  color: #0f0f21;
}
.Search input {
  border: none;
  background: no-repeat;
  outline: none;
  width: 100%;
  font-size: 1.25rem;
}
.Search input::-webkit-input-placeholder {
  color: #332a2a;
  font-size: 1.25rem;
  text-transform: capitalize;
}
.buttonSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 30%;
}
.buttonSection button {
  border: none;
  color: #fff;
  padding: 0.75rem;
  width: 175px;
  font-size: 1.25rem;
  border-radius: 1rem;
  background-color: #1a1e5d;
}

.roleSelected {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  display: flex;
  flex-direction: column;
  width: 75%;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #935d6e;
  padding: 1rem;
  height: 75%;
  background: #f3f3f3;
  border-radius: 10px;
  overflow-y: scroll;
}
.roleCLose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.roleHead {
  display: flex;
  margin: 1rem 0;
  border-bottom: 2px solid #5a6a7a;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.roleHead h4 {
  font-size: 1.5rem;
  margin: 0;
  color: #dc5050;
}
.Change_role {
  border: none;
  background: #8c5a5a;
  font-size: 1.15rem;
  color: #fff;
  padding: 0.25rem;
  width: 150px;
  border-radius: 5px;
}
.userList {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.userList table {
  width: 100%;
  border: 1px solid #999696;
}
.userList th {
  background: #5481d0;
  color: #fff;
  width: 10rem;
  height: 3rem;
  border: 2px solid #999696;
  font-size: 1.35rem;
}
.userList td {
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid #999696;
}
.mentorAdd {
  border: none;
  background: #8c5a5a;
  font-size: 1.15rem;
  color: #fff;
  padding: 0.25rem;
  width: 150px;
  border-radius: 5px;
}
.userList select {
  width: 90%;
  text-align: center;
  border: none;
  outline: none;
  font-size: 1.15rem;
  padding: 0.15rem;
  border-radius: 2rem;
}
.viewButton {
  border: none;
  background: #113b32;
  font-size: 1.15rem;
  color: #fff;
  padding: 0.35rem;
  width: 100px;
  border-radius: 5px;
}
.AssignedMentorList {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #757a92;
  padding: 1rem;
  height: 50%;
  background: #f3f3f3;
  border-radius: 10px;
}
.listClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.listHead {
  display: flex;
  border-bottom: 2px solid #5a6a7a;
  width: 50%;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
.listHead h4 {
  font-size: 1.5rem;
  color: #292972;
}
.allMentorList {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0;
  height: 100%;
  border-radius: 5px;
}
.allMentorList table {
  width: 100%;
}
.allMentorList th {
  background: #737b89;
  color: #fff;
  width: 10rem;
  height: 3rem;
  /* border: 3px solid #5b6082; */
  font-size: 1.25rem;
}
.allMentorList td {
  text-align: center;
  font-size: 1.15rem;
  border: 2px solid #5b6082;
  padding: 0.5rem 0;
}
.allMentorList button {
  width: 135px;
  color: #fff;
  border: none;
  background: #84105d;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
}
.allMentorList select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 1.15rem;
  padding: 0.5rem 0;
  font-weight: 300;
}

.memberCreate {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
}
.memberClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.memberClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.sectionValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.EditsectionValue {
  display: flex;
  align-items: center;
  flex-direction:column;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.editcntryBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.inputValues {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 50%;
}
.inputValues label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.inputValues input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.password {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.password input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.password svg {
  font-size: 1.5rem;
  color: #3d5557;
}

.roleSelection {
  font-family: "Poppins";
  width: 50%;
  font-size: 1.25rem;
  font-weight: 600;
  color: #393979;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem;
  border: 2px solid #bfaaaac1;
  outline: none;
  cursor: pointer;
}
.clubSelection {
  font-family: "Poppins";
  width: 50%;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clubSelection p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #393979;
}
.clubSelection svg {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #121a21;
  cursor: pointer;
}
.clubButton {
  display: flex;
  align-items: center;
}
.clubButton p {
  margin: 0;
  font-size: 1.25rem;
  color: #3e4486;
}
.clubButton svg {
  margin: 0 1rem;
  font-size: 2rem;
  color: #121a21;
}
.clubCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(4%, 112%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
  border: 2px solid #696969c1;
  border-radius: 5px;
  background: #feffff;
  z-index: 222222;
  padding: 0.75rem;
  height: 7rem;
}
.clubEditCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(4%, 112%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  border: 2px solid #696969c1;
  border-radius: 5px;
  background: #feffff;
  z-index: 222222;
  padding: 0.75rem;
  height: 7rem;
}
.clubValue {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0.35rem 0;
}
.clubValue p {
  font-size: 1.15rem;
  color: #191414;
  font-weight: 600;
}
.clubValue input {
  transform: scale(1.5);
  cursor: pointer;
}
.createButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 30%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.errorMessages {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.errorValue p {
  font-size: 1.25rem;
  color: red;
  margin: 1px;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
}
.deletePopup {
  width: 30rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.PasswordPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  width: 20rem;
}
.SharePopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99999;
  width: 20rem;
}
.SharePopupSection {
  display: flex;
  flex-direction: column;
}
.ShareSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem 0.5rem;
}
.SelectType {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.SelectType input {
  cursor: pointer;
  height: 2rem;
  width: 1.2rem;
}
.SelectType label {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0 8px;
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}
.content p {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  text-transform: capitalize;
  font-weight: 500;
}
.content li {
  font-size: 1.15rem;
  font-weight: 400;
  margin: 0.25rem 0;
  list-style: none;
  color: #11114b;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
}
.action button {
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1rem;
  color: #20126d;
  padding: 0.25rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
  width: 5rem;
}

.secondOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgb(4 4 4 / 23%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.passwordReset {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  z-index: 9999;
  width: 25rem;
}
.resetClose {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.resetClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.passworSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
}
.resetInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.resetInput input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.resetInput svg {
  font-size: 1.5rem;
  color: #3d5557;
  cursor: pointer;
}
.confirmResetInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.confirmResetInput input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.confirmResetInput svg {
  font-size: 1.5rem;
  color: #3d5557;
  cursor: pointer;
}

.resetButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.resetUpddate {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.restCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}

.cntryBox {
  width: 100%;
}

.inputValuesBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  gap: 10px;
}

.clubBox {
  width: 100%;
  padding: 5px;
}
