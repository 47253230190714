.Container {
  height: 100vh;
  width: 100%;
  padding: 0 2rem;
}
.header {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #98988d;
  margin-top: 2rem;
}
.header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: rgb(64, 64, 122);
}
.lessonSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;
  border: 2px solid #b3b3b0;
  padding: 1rem 2rem;
    height: 75%;
  background: rgb(237, 237, 237);
  background: radial-gradient(
    circle,
    rgba(237, 237, 237, 1) 0%,
    rgba(207, 207, 207, 1) 100%
  );
}

.lessonHeader {
    display: flex;
    width: 100%;
    background: #7979c6;
    align-items: center;
    line-height: 0;
}
.lessonHeader h4{
    font-size: 1.25rem;
    color: #fff;
    padding: 0 1rem;
}
.lessonDetail {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    /* border:1px solid rgb(48, 48, 42); */
    margin: 1.5rem 0;
    background: #fff
}
.detailLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.detailLeft p{
    font-size: 1.25rem;
    font-weight: 600;
}
.detaiRIght {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.detaiRIght p{
    font-size: 1.25rem;
    font-weight: 600;
}
.topic{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 2px solid #969684d6;
    background: #fff;
    padding: 2rem 0;
    line-height: 0;
}
.topicContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}
.topicContent p{
    font-size: 1.5rem;
    /* padding-left: 15px; */
    border-bottom: 2px solid #a3a380;
    padding: 1rem 2rem;
    margin: 10px;
    width: 50%;
}