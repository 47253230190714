.Container {
  height: 100vh;
  width: 100%;
  padding: 0 1rem;
  background-color: rgb(241, 239, 239);
}
.Header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #3d807c;
  color: #fff;
  margin: 1rem 0;
  padding: 0 3rem;
  border-radius: 1rem;
  line-height: 0;
  height: 70px;
}
.Header h3 {
  font-size: 1.5rem;
}
.categoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}
.categoryGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Card {
    display: flex;
    background: #fff;
    width: 20rem;
    align-items: center;
    justify-content: center;
    border-radius: 1.25rem;
    border: 2px solid #a39797;
    outline: none;
    padding: 0.5rem 0;
}
.Card p {
  color: #857073;
  font-size: 1.25rem;
}
.categoryOverlay{
    width: 84vw;
    height: 80vh;
    position: absolute;
    background-color: transparent;
    top: 10%;
}
.categorySelection{
    width: 65%;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 9;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 1rem;
    height: 50vh;
    padding: 1rem;
}
.categoryClose{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    font-size: 1.5rem;
}
.SelectionHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-bottom: 1px solid #838353;
}
.SelectionHeader h4{
    font-size: 1.5rem;
    margin: 0;
}
.SelectionGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    margin: 1rem;
    padding: 1rem;
    row-gap: 1rem;
}
.sectionCard{
    display: flex;
    width: 10rem;
    align-items: center;
    justify-content: center;
    border: 1px solid #18152c;
    border-radius: 5px;
}
.selectionPopup{
    width: 40%;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 9;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 1rem;
    height: 52vh;
    padding: 1rem;
}
.selectionPopupClose{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    font-size: 1.5rem;
}
.selectionPopupSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #a49c9c;
    width: 90%;
    height: 100%;
    margin: 1rem;
    padding: 1rem 2rem;
    border-radius: 5px;
}
.goal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.skill{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.comment{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
}
.selectionPopupSection p{
  font-size: 1.25rem;  
}
.selectionPopupSection select{
    width: 75%;
    font-size: 1.15rem;
    /* border: none; */
    outline: none;
    border: 2px solid #d6d6ce ;
    color: #1a1a1a;
    padding: 0.5rem;
    border-radius: 1rem;
}
.selectionPopupSection textarea{
    width: 73%;
    font-size: 1.15rem;
    border: 2px solid #d6d6ce ;
    outline: none;
    color: #1a1a1a;
    padding: 0.5rem;
    border-radius: 1rem;
    height: 160px;
}
.goalOverlay{
    width: 80vw;
    height: 80vh;
    position: absolute;
    background-color: transparent;
    top: 10%;
}
.selectionButton{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin: 1rem 0;
}
.selectionButton button{
    font-size: 1.15rem;
    border: none;
    outline: none;
    background: #6c6c72;
    padding: 0.5rem;
    width: 90px;
    border-radius: 10px;
    color: #ffff;
}
.topicContainer{
    width: 40%;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 9;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 1rem;
    height: 52vh;
    padding: 1rem;
}
.topicClose{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    font-size: 1.5rem;
}
.topicSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* border: 1px solid #a49c9c; */
    width: 90%;
    height: 100%;
    margin: 1rem;
    padding: 1rem 2rem;
    border-radius: 5px;
}
.topicSection p{
    background: #e3dfdf;
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    font-size: 1.25rem;
    margin: 0.25rem 0;
    border: 2px solid #babab8;
}
.topicButon{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin: 1rem 0;
}
.topicButon button{
    font-size: 1.15rem;
    border: none;
    outline: none;
    background: #42428f;
    padding: 0.75rem;
    width: 150px;
    border-radius: 10px;
    color: #ffff;
}