.container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
}

.container::-webkit-scrollbar {
    display: none;
}

.header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
        0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.headerText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
}

.headerText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
}
.headerButton {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding:0 1rem;
    gap: 1rem;
  }
  .headerButton button {
    background: #dddfef;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    color: #2d18b3;
    width: 115px;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #2d18b3;
  }
  .headerButton select{
    font-size: 1.1rem;
    border: 2px solid #cbc9c9;
    padding: .25rem;
    outline: none;
    border-radius: 6px;
  }
.sections{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
}
.tabHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}
.tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 13vw;
    border: 1px solid #000;
    border-bottom: none; 
    padding: .5rem 1rem;
    cursor: pointer;
}

.tab:first-child{
    border-radius:  1rem 0 0 0;
}
.tab p{
    font-size: 1rem;
    font-weight: 500;
}
.activeTab{
    background-color: rgb(69, 69, 251);
}
.activeTab p{
    color: #fff;
}
.tabSection{
    border: 2px solid #a6a6a6;
    padding: 1rem;
}
.rewardSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}
.typeContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px -4px 22.8px 0px #f0f0f0;
    padding: 1rem;
}
.typeHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    justify-content: space-between;
}
.typeHeader p{
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
}
.inputSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    gap: .75rem;
    flex-wrap: wrap;
}
.inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
    width: 15%;
}
.inputContainer label{
    color: #858585;
}
.inputContainer input{
    font-size: 1.1rem;
    border: 2px solid #cbc9c9;
    padding: .25rem;
    outline: none;
    border-radius: 6px;
}
.inputContainer select{
    font-size: 1.1rem;
    border: 2px solid #cbc9c9;
    padding: .25rem;
    outline: none;
    border-radius: 6px;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 8%;
}
.buttonContainer button{
    outline: none;
    border: none;
    color: #000;
    background-color: #e2dede;
    padding:  .5rem .7rem;
    border-radius: 6px;
    margin-top: 2rem;
    cursor: pointer;
}
.learningSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

}
.operator p{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    padding: .2rem .5rem;
    width: fit-content;
    font-size: 1rem;
    margin: 0;
}
.badgeContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
}
.badgeHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.badgeHeader p{
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
}
.badgeHeader button{
    background: transparent;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    color: #000000;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid #000000;
}
.badgeTable{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 0;
}
.badgeTable table{
    width: 100%;
    border-collapse: collapse;
}
.badgeTable th{
    background-color: transparent;
    color: #000000;
    text-align: left;
    border: 2px solid #868686;
    padding: 10px;
    font-size: 1.1rem;
    text-align: center;
}
.badgeTable td{
    border: 2px solid #dbdbdb;
    padding: 10px;
}
.popupOverlay{
    background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 9;
  top: 0;
}
.popup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    box-shadow: 0px -4px 22.8px 0px #f0f0f0;
    width: 25vw;
}
.popupHead{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.popupHead p{
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
}
.popupContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 0;
    gap: .75rem;
}
.popupInput{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.popupInput label{
    font-size: .9rem;

}
.popupInput input{
    font-family: "Poppins";
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    text-align: center;
    border-radius: 5px;
    margin: 0.35rem 0;
    border: 1px solid #b2ababc1;
    outline: none;
    text-align: start;
}
.popupInput select{
    font-family: "Poppins";
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    text-align: center;
    border-radius: 5px;
    margin: 0.35rem 0;
    border: 1px solid #b2ababc1;
    outline: none;
    text-align: start;
}
.popupInput input[type="file"] {
    border: none;
    padding: 2px; 
    color: #232327; 
 }
 .popupInput img{
    width: 100px;
 }

.popupInput textarea{
    font-family: "Poppins";
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    text-align: center;
    border-radius: 5px;
    margin: 0.35rem 0;
    border: 1px solid #b2ababc1;
    outline: none;
    text-align: start;
    resize: none;
 }
.popupButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.cancel{
    border-radius: 6px;
    background-color: #eee6e1;
    border: none;
    outline: none;
    font-size: 1.1rem;
    color: #b01616;
    padding: 0.5rem 1.5rem;
    border: 2px solid #f9a1a1;
    cursor: pointer;
}
.create {
    border-radius: 6px;
    background-color: #e1e4ee;
    outline: none;
    font-size: 1.1rem;
    color: #20126d;
    padding: 0.5rem 1.5rem;
    border: 2px solid #b2a5d3;
    cursor: pointer;
  }
  .popupText{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .popupText p{
    margin: 0;
    font-size: 1.2rem;
    padding: 1rem 0;
    font-weight: 600;
  }
  .editIcon{
    color: forestgreen;
    font-size: 1.2rem;
    margin: 0 .5rem; 
    cursor: pointer;
  }
  .deleteIcon{
    color: #b01616;
    font-size: 1.2rem;
    margin: 0 .5rem;
    cursor: pointer;
  }
  .selectionInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70%;
  }
  .selectionInfo p{
    color: #b01616;
    font-size: 1.2rem;
  }