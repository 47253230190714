.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
  padding-left: 1rem;
}
.reportDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 1rem;
}
.tabHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.headerRight select {
  border: 1px solid #e0e5f2;
  width: 50%;
  height: 2.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
}

.errorMessage{
  padding: 1rem;
  background-color: #fa000062;
}

.tabValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-left: 0.5rem;
  cursor: pointer;
}
.tabValue p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0e0e0ebd;
}
.activeTabValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  cursor: pointer;
  background: #dedede;
}
.activeTabValue p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0e0e0ebd;
}
.tabBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.selectionSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}
.selectionInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 25%;
}
.selectionInput label {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2b3674;
}
.selectionInput input,
.selectionInput select {
  border: 1px solid #e0e5f2;
  width: 90%;
  height: 2.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
}

.userInputContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.userInputTop {
  width: 90%;
  height: 2.5rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e5f2;
  cursor: pointer;
}
.userInputTop p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  padding-left: 1rem;
}
.userInputTop svg {
  color: #a3aed0;
  margin-right: 1rem;
}
.userInputBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  position: absolute;
  border: 1px solid #e0e5f2;
  transform: translate(0%, 20%);
  border-radius: 6px;
  height: 15rem;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999999;
  background: #fff;
  padding: 1rem 0;
}
.userInputBottom::-webkit-scrollbar {
  display: none;
}
.inputBottomValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
}
.inputBottomValue input {
  width: 18px;
  height: 18px;
  margin-left: 1rem;
  margin-top: 0;
}
.inputBottomValue p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #444c65;
  padding-left: 1rem;
}
.tabSelectionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1rem;
}
.tabSelectionSection thead th {
  background: #ededed;
}
.tabSelectionSection th {
  width: 20%;
  text-align: center;
}
.tabSelectionSection th button {
  width: 10rem;
  height: 3rem;
  cursor: pointer;
  background: #dedede;
  border: none;
  border-radius: 6px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0e0e0ebd;
}
