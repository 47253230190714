.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 0.5rem;
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    border-radius: 10px;
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .HeaderText {
    width: 80%;
    display: flex;
    align-items: center;
    padding:.5rem;
    justify-content: flex-start;
  }
  .HeaderText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .HeadButton {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
  }
  .HeadButton button {
    background: #49618b;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    width: 115px;
    cursor: pointer;
  }
  .Invoice{
    display: flex;
    flex-direction: column;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin: 0 1rem;
    padding:.2rem 1rem;
  }
  .InputContainer{
    display: flex;
    flex-direction: row;
    margin: 2px 0;
    justify-content: center;
    align-items: center;
  }
  .Label{
    font-weight: 500;
    width: 8rem;
  }
  .InputContainer input {
    font-family: "Poppins";
    width: 90%;
    font-size: 1rem;
    padding: 0.2rem 0;
    /* text-align: center; */
    margin: 0.2rem 0;
    border: none;
    border-bottom: 1px dotted #696969c1;
    outline: none;
    color: #3e4486;
  }
  .InputContainer input::-webkit-input-placeholder {
    /* text-align: center; */
    text-transform: uppercase;
    font-size: .9rem;
    color: #a7a6a6;
  }
  .InputContainer textarea{
    width: 90%;
    resize: none;
    outline: none;
    color: #3e4486;
    font-size: 1rem;
    border: none;
    border-bottom: 1px dotted #696969c1;
  }
  .InputContainer textarea::-webkit-input-placeholder {
    /* text-align: center; */
    text-transform: uppercase;
    font-size: .9rem;
    color: #a7a6a6;
  }
  .InputTable{
    width: 100%;
    border-spacing: 0;
  }
   .InputTable input{
    width: 90%;
    border: none;
    outline: none;
   } 
   .InputTable tr {
    text-align: center;
  }
  .InputTable th {
    height: 3rem;
    color: #fff;
    font-size: 1rem;
    color: #535370;
    background-color: #f8faff;
    border: 1px solid #dedede;
  }
  .InputTable td{
    border: 1px solid #b3b1b1;
    height: 3rem;
  }
  .InputTable th:nth-child(8),
  .InputTable  td:nth-child(8) {
      width: 70px; 
    }
  .InvoiceButton{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2px 0;
  }
  .TableButton{
    background: #adc2e0;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #2445b3;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #568cbe;
  margin: .4rem 1rem;
  }
  .InvoiceButton button{
    background: #adc2e0;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #2445b3;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #568cbe;
  margin: 0 1rem;
  }
  
  .invoicehead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
  }
  .InvoiceNumber {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .InvoiceDate{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .UserSlector{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 2px 0;
    margin: 3px 0;
  }
  
  .InvoiceDate input{
    border: none;
    border-bottom: 1px solid;
    font-size: 1rem;
    outline: none;
  }
  .InputTable select{
    border: none;
    background: none;
    border-bottom: 2px solid #939393;
    padding: .5rem 0;
    width: 200px;
    outline: none;
    font-size: 1rem;
    color: #8f2f2f;
  }
  
  .errorMessages {
    padding: 0rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: red;
    font-size: 1rem;
  }
  .errorMessages p{
    margin: 2px 0;
  }