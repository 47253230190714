.Container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form{
    padding: 1rem 1rem;
    border-radius: 1.7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(177, 174, 174, 0.69);
}
.Input {
 width: 100%;
  font-size: 1rem;
  padding:.7rem 0;
  text-align: center;
  border-radius: 12px;
  margin: 5px ;
  border: none;
  outline:none;
}
.form .formText{
    font-size: 1.5rem;
    margin: 6px;
   font-weight: 600;
}
.Header{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
}
.Register{
    width: 20rem;
}
.formButton{
    width: 50%;
    font-size: 1rem;
    padding: .7rem;
    text-align: center;
    border-radius: 4rem;
    margin: 1rem 0;
    border: none;
  background-color:#3b82f6 ;
  
  }