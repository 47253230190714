.container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
  .container::-webkit-scrollbar {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    /* border-radius: 10px; */
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .headerText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
  }
  .headerText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .secondHead {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .selectLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
  }
  .select_section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    border: 2px solid #efefef;
    border-radius: 16px;
    margin: 1rem;
    box-shadow: 0 3px 8px #0000001f;
  }
  .org_select {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .org_select select {
    border: none;
    background: none;
    border-bottom: 2px solid #939393;
    padding: 1rem 0;
    width: 300px;
    outline: none;
    font-size: 1.25rem;
    color: #8f2f2f;
  }
  .club_select {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .club_select select {
    border: none;
    background: none;
    border-bottom: 2px solid #939393;
    padding: 1rem 0;
    width: 300px;
    outline: none;
    font-size: 1.25rem;
    color: #8f2f2f;
  }
  .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1rem;
  }