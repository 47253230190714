.Container {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100%;
  background-image: url("../../assets/one_on_one_background.jpg");
}
.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.logo {
  width: 10rem;
  margin: 1rem 0;
}
.logo img {
  width: 100%;
  height: 100%;
}
.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 28rem;
  padding: 1rem;
  border-radius: 3rem;
  background: #ffffff3d;
}
.HeaderContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  /* padding: 1rem 0; */
  border: 2px solid #fff;
}
.HeaderContent h4 {
  /* margin: 0; */
  font-size: 1.75rem;
  color: #fff;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
  /* border: 2px solid #fff; */
  border-radius: 1rem;
  padding: 1rem 0;
}

.input {
  width: 85%;
  padding: 0.75rem 0;
  margin: 0.5rem 0;
  text-align: center;
  outline: none;
  font-size: 1.25rem;
  border-radius: 2rem;
  border: none;
  color: rgb(29 31 87);
}
.loginForm input::placeholder {
  color: #2b3b64f0;
}
.password {
  width: 85%;
  padding: 0.75rem 0;
  margin: 0.5rem 0;
  outline: none;
  font-size: 1.25rem;
  border-radius: 2rem;
  border: none;
  color: rgb(29 31 87);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.password input::placeholder {
  color: #2b3b64f0;
}
.password input {
  outline: none;
  border: none;
  font-size: 1.25rem;
  text-align: center;
  width: 78%;
}
.password svg {
  width: 10%;
  font-size: 1.5rem;
  color: #2b3b64f0;
  margin: 0 0.5rem;
}
.loginForm button {
  border: none;
  font-size: 1.25rem;
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 120px;
  background: white;
  border-radius: 1.5rem;
  color: #2b3b64f0;
  /* font-weight: 600; */
  cursor: pointer;
}

.loginForm button:hover {
  background: #f8f800;
  color: #000000;
}
.loginForm p {
  margin: 0;
  color: red;
  font-size: 1.25rem;
}
