.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
  padding-left: 1rem;
}
.eventDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 100%;
  padding: 1rem;
}
.eventTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.eventTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
}
.eventTitle p {
  font-family: "Poppins";
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #293353;
}
.eventTitle span {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #505153;
  margin-top: 1rem;
}
.schedule {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
}
.schedule p {
  font-family: "Poppins";
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #293353;
  padding-left: 1rem;
}
.scheduleDetails {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.eventInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 31%;
  margin-top: 1rem;
}
.eventInput label {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2b3674;
}
.eventInput input {
  border: 1px solid #e0e5f2;
  width: 95%;
  height: 2.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
}
.eventBottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
}
.tableSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.tableSelect input {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.tableInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.tableInput input {
  border: 1px solid #e0e5f2;
  width: 85%;
  height: 2rem;
  border-radius: 8px;
  padding-left: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a3aed0;
  outline: none;
  cursor: pointer;
}

.attendanceButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.attendedButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.attendedButton button {
  background-color: #e0e5f2;
  border: none;
  width: 150px;
  height: 2.5rem;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  cursor: pointer;
  margin-top: 1rem;
}
