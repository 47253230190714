.Container {
  width: 100%;
  height: 100vh;
}
.Header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Title {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.Title p {
  font-weight: 500;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.lessonSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem;
  border-radius: 5px;
  padding: 1rem;
}
.ClubName {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  border-bottom: 2px solid#424360;
  margin: 1rem;
}
.ClubName h4 {
  font-size: 1.5rem;
  margin: 0;
  color: #3939a3;
}
.lessonGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
  width: 100%;
}
.card {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.5rem;
  /* height: 8rem; */
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.cardHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.cardHead p {
  font-size: 1.25rem;
  color: #857073;
  text-transform: capitalize;
  font-weight: 500;
}
.cardBottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem;
}
.bottomFirst {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem 0;
}
.inputValues {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.inputValues p {
  color: #321e1e;
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 600;
}
.inputValues span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.inputValues p:nth-child(3) {
  color: #8f2f2f;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.bottomSecond {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0.5rem 0;
}
.timeline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.timeline p {
  color: #364d89;
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 600;
}
.timeline span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.timeline p:nth-child(3) {
  color: #b4c518;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
.plan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.plan p {
  color: #364d89;
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 600;
}
.plan span {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: black;
}
.plan p:nth-child(3) {
  color: #7d2271;
  font-size: 1.15rem;
  text-transform: capitalize;
  font-weight: 500;
}
