.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* padding: 0 1rem; */
  background-color: #fff;
}
.Container::-webkit-scrollbar {
  display: none;
}
.LessonDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  padding: 0 0.75rem;
}
.LessonHead {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 5rem;
  padding: 0.25rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-top: 1rem;
}
.navLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}
.navLinks span {
  font-size: 1.25rem;
  margin: 0 0.25rem;
}
.linkLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkLeft p {
  font-size: 1.25rem;
  color: #353588;
  cursor: pointer;
  text-transform: capitalize;
}
.linkRight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkRight p {
  font-size: 1.25rem;
  color: #bd2f2f;
  cursor: pointer;
  text-transform: capitalize;
}
.topicList {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
.lessonButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.lessonButtonLeft {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}
.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Pagination p {
  color: #070785;
  font-size: 1.25rem;
}
.Pagination p:nth-child(2) {
  color: #ee3d3d;
  font-size: 1.25rem;
}
.Pagination span {
  color: #616860;
  font-size: 1.25rem;
  margin: 0 0.25rem;
}
.lessonButtonRight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.chapterCreate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}
.chapterCreate svg {
  font-size: 2rem;
  color: #7e193e;
  cursor: pointer;
}
.chapterEdit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.chapterEdit svg {
  font-size: 2rem;
  color: #56197e;
  cursor: pointer;
}
.chapterDelete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}
.chapterDelete svg {
  font-size: 2rem;
  color: rgb(231, 29, 29);
  cursor: pointer;
}
.assessmentView {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.assessmentView svg {
  font-size: 2rem;
  color: #57aa3f;
  cursor: pointer;
}

.topicDetal {
  padding: 1rem;
  height: 80vh;
  background-color: #fff;
  border: 2px solid #bbb8b8;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.75rem 0;
}
.topicDetal p {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.imageView img {
  width: 100%;
  height: 100%;
}
.loader {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.noSelection {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.lessonCreatePopup {
  width: 50rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 2px solid #bdbde6;
  /* height: 20rem; */
  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem;
}
.popupclose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.25rem;
}
.editSection {
  /* height: 20rem; */
  width: 100%;
  padding: 1rem;
  height: 75%;

}
.addButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1rem;
}
.addButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #2291f2;
  padding: 10px 1.5rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
  border-radius: 6px;
  margin: 1.5rem 0;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.createList {
  width: 50rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 2px solid #c0c0c0; */
  /* height: 20rem; */
  background-color: #fff;
  border-radius: 1rem;
  min-height: 20rem;
}
.listHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: #0f8bea;
  padding: 0.75rem;
  border-radius: 15px 15px 0 0;
}
.listText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}
.listText p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
}
.listClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.listClose svg {
  font-size: 1.25rem;
  cursor: pointer;
  color: #fff;
}
.listSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
}
.sectionValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.25rem 0;
}
.sectionValue input {
  cursor: pointer;
  height: 2rem;
  width: 1.2rem;
}
.sectionValue p {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0 8px;
}
.createListLesson {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* border: 2px solid #c0c0c0; */
  /* height: 20rem; */
  background-color: #fff;
  border-radius: 1rem;
  height: 73vh;
}
.imageUpload {
  width: 50rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #c0c0c0;
  height: 15rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem;
}
.uploadHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.25rem;
  background: #cbcb87;
  padding: 0.5rem;
}
.uploadHead p {
  margin: 0;
  font-size: 1.5rem;
  color: #080062;
}
.uploadSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1rem 0;
  justify-content: space-between;
  height: 100%;
  padding: 0 2rem;
}
.inputSection {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.inputSection input {
  font-size: 1.25rem;
  color: #86869b;
}
.UploadButton {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.UploadButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #2291f2;
  padding: 10px 1.5rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
  border-radius: 6px;
  margin: 1rem 0;
}

.topicName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  padding: 0 0.5rem;
}

.SecondHead {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.Blank {
  color: transparent;
  opacity: 0;
}

.aigenerate {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* align-items: center; */
  padding: 1rem 2rem;
  width: 100%;
}
.aigenerate textarea {
  height: 100px;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem;
  resize: none;
}
.aigenerateButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.aigenerateButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #2291f2;
  padding: 10px 1.5rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
  border-radius: 6px;
  margin: 2rem 0;
}
.TitleInput input {
  width: 100%;
  height: 3rem;
  font-size: 1.1rem;
  outline: none;
  border-radius: 6px;
  border: none;
  box-shadow: 0 3px 8px #0000003d;
  padding: 0 4px;
}
.TitleInput {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: 100%;
  margin: 6px 0;
}
.DeletePopup {
  width: 30rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}
.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.MediaPopup {
  position: absolute;
  top: 50%;
  left: 57%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 80%;
}
.MediaPopupHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 3px 8px #0000001f;
  padding: 0.5rem 0;
}
.MediaPopupHeaderText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.MediaPopupHeaderText h5 {
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #727272;
  padding: 0 6px;
}
.MediaPopupHeaderClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.MediaPopupHeaderClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.MediaPopupSection {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.ImageGRid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  height: 60vh;
  padding: 8px;
  overflow-y: scroll;
}
.ImageGRid::-webkit-scrollbar{
  display: none;
}
.Card{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  height: 12rem;
}
.Card img{
width: 100%;
}
.tabSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.MediaPopupInputTab{
  height: 60vh;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
}
.MediaPopupInputTab button {
  border: none;
  background: #9f2f2f;
  font-size: 1.25rem;
  color: #ffff;
  padding: 0.75rem;
  width: 150px;
  border-radius: 5px;
  margin: 0 0.75rem;
}

.background-blot {
  padding: 10px; /* Adjust padding as needed */
}
