.container {
  width: 100%;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 500;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.headerButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
}
.headerButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  width: 115px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
}
.orgSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
}
.quizListingSection {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1.5rem 1rem;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #eff8ff;
  height: 75vh;
  overflow-y: scroll;
}
.quizListingSection::-webkit-scrollbar {
  display: none;
}
.quizGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}

.quizEditIcon,
.quizSettings,
.quizCreateIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quizEditIcon svg {
  color: #239f9f;
}
.quizSettings svg {
  color: #692363;
}

.quizCreateIcon svg {
  color: #1f901f;
}
.settingsListing {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
}
.settingHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 3px 8px #0000001f;
  padding: 0.5rem 0;
}
.headerSettingText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerSettingText h5 {
  font-size: 1.15rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #727272;
}
.settingClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.settingClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.settingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 35rem;
  margin: 0.5rem 0;
}
.mainSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.25rem 0;
}
.sectionValues {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
  justify-content: flex-start;
}
.sectionValues label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.sectionValues input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.settingButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.add {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}