.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    border-radius: 10px;
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .headerText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .headerText h5 {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .headerButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
  }
  .headerButton button {
    background: #efe4dd;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #7e193e;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #be5656;
  }
  .FilterSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #dbdbdb;
    margin: 1rem;
    padding: .5rem 0;
    justify-content: space-between;
  }
  .LeftSection{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .RightSection{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .ViewContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 14%;
  }
  .ListView{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .GridView{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .MediaContainer{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 1rem;
  }
  .ViewContainer svg{
    font-size: 1.5rem;
  }
  .active svg {
    color: rgb(36, 93, 216); /* Set the color to blue for the active state */
}
  .SearchContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
    align-items: center;
  }
  .GalleryContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem ;
  }
  .Grid{
    display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  }
  
  .uploadPopup {
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 10px;
    padding: 1.25rem;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .uploadPopupHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0 3px 8px #0000001f;
    padding: 0.5rem 0;
  }
  .uploadPopupHeaderText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadPopupHeaderText h5 {
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #727272;
  }
  .uploadPopupHeaderClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .uploadPopupHeaderClose svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .uploadPopupSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
  }
  .sectionValues {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sectionValues label {
    font-size: 1.15rem;
    font-weight: 500;
    color: #393979;
  }
  .sectionValues input {
    font-family: "Poppins";
    width: 100%;
    font-size: 1.25rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 2px solid #bfaaaac1;
    outline: none;
    text-align: start;
  }
  .sectionValues input[type="file"] {
    font-family: "Poppins";
    width: 100%;
    font-size: 1.25rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: none;
    outline: none;
    text-align: start;
  }

  .uploadPopupButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .add {
    width: 10rem;
    border-radius: 6px;
    background-color: #e1e4ee;
    outline: none;
    font-size: 1.15rem;
    color: #20126d;
    padding: 0.5rem;
    border: 2px solid #b2a5d3;
    cursor: pointer;
  }
  .cancel {
    width: 10rem;
    border-radius: 6px;
    background-color: #eee6e1;
    border: none;
    outline: none;
    font-size: 1.15rem;
    color: #b01616;
    padding: 0.5rem;
    border: 2px solid #f9a1a1;
    cursor: pointer;
  }
  .DetailsPopup {
    position: absolute;
    top: 50%;
    left: 57%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 80%;
  }
  .DetailsPopupHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0 3px 8px #0000001f;
    padding: 0.5rem 0;
  }
  .DetailsPopupHeaderText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .DetailsPopupHeaderText h5 {
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #727272;
    padding: 0 6px;
  }
  .DetailsPopupHeaderClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .DetailsPopupHeaderClose svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .DetailsPopupSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
  }
  .DetailsPopupLeftSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
  .DetailsPopupRightSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40%;
    height: 38rem;
    overflow-y: scroll;
    background-color: #f5f5f5;
  }
  .Card{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    height: 15rem;
  }
  .Card img{
 width: 100%;
  }
  .FileDetails{
    width: 100%;
    border-bottom: 1px solid;
    padding: 8px;
  }
  .FileDetails p{
    padding: 4px;
  }
  .Discription{
    display: flex;
    flex-direction: column;
    padding: 4px;
    width: 100%;
  }
  .DiscriptionSection{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .DiscriptionLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 40%;
  }
  .DiscriptionRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
  }
  .DiscriptionRight input {
    font-family: "Poppins";
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 1px solid #bfaaaac1;
    outline: none;
    text-align: start;
  }
  .DiscriptionRight textarea {
    font-family: "Poppins";
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 1px solid #bfaaaac1;
    outline: none;
    text-align: start;
    resize: none;
  }
  .DiscriptionRight button{
    background: #ddddef;
    font-size: 1rem;
    outline: none;
    border-radius: 6px;
    padding: 0.25rem;
    color: #192d7e;
    cursor: pointer;
    border: 2px solid #5662be;
  }
  .DiscriptionButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
  }
  .Update {
    width: 10rem;
    border-radius: 6px;
    background-color: #e1e4ee;
    outline: none;
    font-size: 1.15rem;
    color: #20126d;
    padding: 0.5rem;
    border: 2px solid #b2a5d3;
    cursor: pointer;
  }
  .Cancel {
    width: 10rem;
    border-radius: 6px;
    background-color: #eee6e1;
    border: none;
    outline: none;
    font-size: 1.15rem;
    color: #b01616;
    padding: 0.5rem;
    border: 2px solid #f9a1a1;
    cursor: pointer;
  }
.List{
  width: 100%;

}
.DetailPopupImage{
  width: 24rem;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  position: relative;
}
.DetailPopupImage img{
width: 100%;
height: 100%;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.ImagePlaceholder{
  position: absolute;
  right: 1%;
  bottom: 1%;
  padding: 6px;
  background: #eee6e1;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ImagePlaceholder svg{
  font-size: 2rem;
}
.EditImage{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.EditImage button{
  background: #ddddef;
    font-size: 1rem;
    outline: none;
    border-radius: 6px;
    padding: 0.25rem;
    color: #192d7e;
    cursor: pointer;
    border: 2px solid #5662be;
    margin: 1rem 0;
}
.List {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin:0.5rem 0 ;
  flex-direction: column;
  padding: 0.3rem;
}

.List table {
  width: 100%;
  border-spacing: 0;
  /* border: 2px solid #a8b1b9; */
  border-radius: 5px;

}
.List thead {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 3rem;

}
.List tbody tr {
  text-align: center;
  height: 3rem;
}
.List th {
  color: #cc3300;
  font-size: 1.25rem;
  font-weight: 400;
  width: 10rem;
  padding: 0.5rem;
  border-bottom: 2px solid thistle;
  position: sticky;
  top: -8px;
  background: #ffff;

}
.List td {
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
font-size: 1.15rem;
}

.List td:first-child{
 text-align: left;
 width: 50%;
 color: #b30059;
  cursor: pointer;
}

.List tbody tr:hover {
  background-color: #ececec;
}
.deletePopup {
  width: 30rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
