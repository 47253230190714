.container {
  width: 100%;
  height: 100vh;
}
.container::-webkit-scrollbar {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  /* border-radius: 10px; */
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 500;
  margin: 0;
  font-size: 1.35rem;
  text-transform: uppercase;
  padding-left: 1rem;
}
.headerButton {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.headerButton button {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  width: 220px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
  margin-right: 1rem;
}
.planningSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.secondHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.select_section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 1rem;
  border: 2px solid #efefef;
  border-radius: 16px;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000001f;
}
.gridList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
  width: 100%;
}
.sessionCard {
  display: flex;
  background: #fff;
  width: 15rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
  height: 8rem;
}
.cardTop,
.cardBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
}
.cardTop p {
  font-family: "Poppins";
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #455279;
}
.viewButton {
  color: #b4b409;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000002b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.deletePopup {
  width: 30rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.deleteClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
