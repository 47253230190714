.Container {
  /* height: 100vh; */
  width: 100%;
  padding: 0 1rem;
  background-color: rgb(241, 239, 239);
  min-height: 100vh;

}
.topHeader {
  display: flex;
  margin: 1rem 0;
  padding: 0 3rem;
  background: #1a1e5d;
  color: #fff;
  border-radius: 1rem;
  height: 65px;
  align-items: center;
  justify-content: flex-start;
}
.topHeader h3 {
  font-size: 1.5rem;
  margin: 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #8d8790;
  /* border-radius: 3rem; */
  height: 100px;
  padding: 0 5rem;
}
.header_Orgname {
  width: 30%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* border-bottom: 2px solid #8d8790; */
  color: #0e286f;
  margin: 0 2rem;
}
.header_Orgname h4 {
  font-size: 1.25rem;
  margin: 0;
}
.header_Clubname {
  width: 30%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* border-bottom: 2px solid #8d8790; */
  color: #ac0808;
  margin: 0 2rem;
}
.header_Clubname h4 {
  font-size: 1.25rem;
  margin: 0;
}
.header_Username {
  width: 30%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* border-bottom: 2px solid #8d8790; */
  color: #066b4d;
  margin: 0 2rem;
}
.header_Username h4 {
  font-size: 1.25rem;
  margin: 0;
}
.Lessonmapping {
  width: 30%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.Lessonmapping button {
  border: none;
  outline: none;
  background: #8d8790;
  width: 180px;
  padding: 0.75rem;
  font-size: 1.25rem;
  color: #fff;
  border-radius: 15px;
}
.existingCategories {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  /* padding: 1rem; */
  border: 2px solid #8d8790;
  border-radius: 5px;
  /* height: 100%; */
  height: fit-content;
  width: 100%;
}
.userLesson {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userLesson h2 {
  color: #212a32;
  border-bottom: 2px solid #666648;
  padding: 0 0 1rem;
  width: 50%;
  text-align: center;
}
.categoryGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.card {
  display: flex;
  flex-direction: column;
  /* width: 10rem; */
  /* height: 10rem; */
  border: 1px solid #160194;
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 2;
}
.CardHead p {
  font-size: 1.5rem;
  margin: 0;
  color: #970707;
}
.cardDetail {
  display: flex;
  flex-direction: column;
}
.cardDetail p {
  font-size: 1.25rem;
  margin: 0;
  color: #0b2368;
}
