.EraseButton{
    background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.5rem;
  color: #56197e;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid #6356be;
  margin: 0.1rem 0.25rem;
}