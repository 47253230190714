.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* background-color: rgb(241, 239, 239); */
  /* padding: 0 0.5rem; */
}
.Container::-webkit-scrollbar {
  display: none;
}
.HeaderDiv {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Title {
  width: 65%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.Title p {
  font-size: 1.25rem;
  margin: 0;
  color: #286397;
  text-transform: capitalize;
  cursor: pointer;
}
.Title span {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  color: #422f2f;
}
.headButtons {
  /* width: 25%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.AddButton {
  background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #56197e;
  width: 170px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #6356be;
  margin: 0 0.25rem;
}
.existButton {
  background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  width: 150px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
  margin: 0 0.25rem;
}
.List {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.table {
  width: 100%;
  border-spacing: 0;
}
.table tr {
  text-align: center;
}
.table th {
  background-color: #542b47;
  width: 10rem;
  height: 3rem;
  color: #fff;
  border: 1px solid #4a4450;
  font-size: 1.25rem;
}
.table td {
  height: 3rem;
  border: 1px solid #a08ea4;
  color: #2f6f72;
}
.Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
}
.iconContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipText {
  visibility: hidden;
  width: 80px;
  background-color: #857c7c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px; */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1rem;
}

.iconContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
.passwordRest {
  color: #168a23;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}
.calanderSchedule {
  color: #93046f;
}

.passwordInput {
  border: none;
  background-color: transparent;
  /* width: 100%; */
  text-align: center;
  font-size: 1rem;
}

.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 9;
}
.learningOverlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
}

.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 8rem;
  z-index: 99;
}
.popupText p {
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 25px;
}
.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.PopupButton button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
}
.memberCreate {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  z-index: 99;
}
.memberClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.memberClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.membersection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  /* height: 70%; */
}
.sectionValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.inputValues {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.25rem;
  justify-content: flex-start;
  width: 50%;
}
.inputValues label {
  font-size: 1.15rem;
  font-weight: 500;
  color: #393979;
}
.inputValues input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.password {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.password input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.password svg {
  font-size: 1.5rem;
  color: #3d5557;
  cursor: pointer;
}

.roleSelection {
  font-family: "Poppins";
  width: 50%;
  font-size: 1.25rem;
  font-weight: 600;
  color: #393979;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem;
  border: 2px solid #bfaaaac1;
  outline: none;
  cursor: pointer;
}
.clubSelection {
  font-family: "Poppins";
  width: 50%;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clubSelection p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #393979;
}
.clubSelection svg {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #121a21;
  cursor: pointer;
}
.clubCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 25%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 2px solid #696969c1;
  border-radius: 5px;
  background: #feffff;
  z-index: 222222;
  padding: 0.75rem;
}
.clubCheckUpdate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(5%, 125%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  border: 2px solid #696969c1;
  border-radius: 5px;
  background: #feffff;
  z-index: 222222;
  padding: 0.75rem;
}
.clubValue {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0.35rem 0;
}
.clubValue p {
  font-size: 1.15rem;
  color: #191414;
  font-weight: 600;
}
.clubValue input {
  transform: scale(1.5);
  cursor: pointer;
}
.createButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* height: 30%; */
  padding: 1rem;
}
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.errorMessages {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.errorValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.errorValue p {
  font-size: 1.25rem;
  color: red;
  margin: 1px;
}
.tabSection {
  display: flex;
  color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.categoryList {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 0 3px 8px #0000003d;
}
.categoryGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Card {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.5rem;
  height: 8rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.cardTop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.cardTop p {
  font-size: 1.25rem;
  color: #857073;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
}
.cardAction {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  /* padding: 1rem; */
  height: 50%;
}

.categorySelection {
  width: 75%;
  position: absolute;
  top: 45%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 60vh;
  padding: 1rem;
}
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  padding: 1rem 0.5rem;
}
.navLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}
.navLinks span {
  color: #c71313;
  font-size: 1.25rem;
  margin: 0 0.25rem;
}
.linkLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.linkLeft p {
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #004f96;
  cursor: pointer;
}
.linkRight {
  display: flex;
  align-items: center;
}
.linkRight span {
  font-size: 1.25rem;
  color: #d4bd33;
}
.linkRight p {
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #1a8f8f;
  cursor: pointer;
}
.headerName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}
.headerName p {
  font-size: 1.35rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #8f518f;
}
.goalHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.goalHeader p {
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #004f96;
  cursor: pointer;
}
.headerClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
}
.headerClose svg {
  font-size: 1.35rem;
  cursor: pointer;
}
.SelectionGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  row-gap: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.sectionCard {
  display: flex;
  background: #fff;
  /* width: 15rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  /* border: 2px solid #a39797; */
  /* outline: none; */
  padding: 0.5rem;
  height: 8rem;
  flex-direction: column;
  box-shadow: 0 3px 8px #0000003d;
}
.cardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.cardContent p {
  font-size: 1.25rem;
  color: #857073;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
}
.cardBottom {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  /* padding: 1rem; */
  height: 50%;
}
.viewButton {
  color: #b4b409;
}
.courseMap {
  color: #09b409;
}
.selectionPopup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  padding: 1rem;
}
.selectionPopupClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.selectionPopupClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.selectionPopupSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80%;
}
.selectionTop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.comment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.selectionButton {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  height: 20%;
}
.selectionButton button {
  width: 8rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.topicContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  height: 100%;
  overflow: hidden;
}
.topicClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.topicClose svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.topicSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80%;
  margin: 0.5rem 0;
  overflow-y: scroll;
  padding: 0.5rem 0;
}
.topicButon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  height: 20%;
}
.topicButon button {
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.shedule {
  border-radius: 10px;
  margin: 1rem 0;
  border: 2px solid #590b0b;
  padding: 6px 1rem;
}
.deletePopup {
  width: 30rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}
.deleteClose svg {
  font-size: 1.25rem;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.3rem;
  width: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0rem 0;
}
.popupText p {
  color: #857073;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
  padding: 1rem 0;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0.2rem 0;
}
.orgDelete {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}
.orgDeleteCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}

.assignedClub {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.assignedClub button {
  background: #fbe0ff;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #cf4883;
  width: 170px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be56a0;
  margin: 0 0.25rem;
}
.AddressSection {
  width: 35rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.AddressSection form {
  background-color: #f5f5f5;
  padding: 2rem 2rem;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
}
.AddressSection form input {
  /* width: 100%; */
  font-size: 1rem;
  background-color: #fff;
  padding: 8px 1px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 1px solid #696969c1;
  outline: none;
}
.Time {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  justify-content: space-between;
}
.TimeSecond {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.Description {
  display: flex;
  flex-direction: column;
}
.FormButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.FormButton button {
  border-radius: 5px;
  background-color: #3b82f6;
  border: none;
  outline: none;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  padding: 2px 10px;
  margin: 0 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.ViewPopup {
  width: 30rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  z-index: 9999;
  min-height: 5rem;
  height: fit-content;
  background-color: #fff;
  padding: 10px 1rem;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  box-shadow: 2px 2px #888888;
}
.Icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: fit-content;
  align-items: center;
}
.EditIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 6rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.start p {
  margin: 0;
}
.PasswordPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  width: 20rem;
}
.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}
.content p {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  text-transform: capitalize;
  font-weight: 500;
}
.content li {
  font-size: 1.15rem;
  font-weight: 400;
  margin: 0.25rem 0;
  list-style: none;
  color: #11114b;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
}
.action button {
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1rem;
  color: #20126d;
  padding: 0.25rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
  width: 5rem;
}
.secondOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgb(4 4 4 / 23%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.passwordReset {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  padding: 1.25rem;
  align-items: center;
  z-index: 9999;
  width: 25rem;
}
.resetClose {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.resetClose svg {
  font-size: 1.25rem;
  cursor: pointer;
}
.passworSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
}
.resetInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.resetInput input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.resetInput svg {
  font-size: 1.5rem;
  color: #3d5557;
  cursor: pointer;
}
.confirmResetInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  margin: 0.5rem 0;
  border: 2px solid #bfaaaac1;
  outline: none;
}
.confirmResetInput input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.confirmResetInput svg {
  font-size: 1.5rem;
  color: #3d5557;
  cursor: pointer;
}

.resetButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.resetUpddate {
  width: 10rem;
  border-radius: 6px;
  background-color: #e1e4ee;
  outline: none;
  font-size: 1.15rem;
  color: #20126d;
  padding: 0.5rem;
  border: 2px solid #b2a5d3;
  cursor: pointer;
}
.restCancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #eee6e1;
  border: none;
  outline: none;
  font-size: 1.15rem;
  color: #b01616;
  padding: 0.5rem;
  border: 2px solid #f9a1a1;
  cursor: pointer;
}

.sectionValueBox{
  display: flex;
  gap: 10px;
}
.sectionValueBoxMain{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

}
